import Vue from 'vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, max, min, numeric, required } from 'vee-validate/dist/rules';

extend('email', email);
extend('max', max);
extend('min', min);
extend('numeric', numeric);
extend('required', required);

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
