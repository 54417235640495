export const sidebar = {
  state: {
    drawerMinified: false,
    showDrawerInMobile: true,
  },
  mutations: {
    SET_SHOW_DRAWER_IN_MOBILE(state, payload) {
      state.showDrawerInMobile =
        payload !== undefined ? payload : !state.showDrawerInMobile;
    },
    SET_DRAWER_MINIFIED(state, payload) {
      state.drawerMinified =
        payload !== undefined ? payload : !state.drawerMinified;
    },
  },
};
